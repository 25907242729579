import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import sentryvision from "../../Assets/Projects/sentyvision.jpg";
import sustainhub from "../../Assets/Projects/sustainhub.png";
import movify from "../../Assets/Projects/movify2.png";
import medfix from "../../Assets/Projects/medfix.png";
import cv_maker from "../../Assets/Projects/cv_maker.png";
import muskaan from "../../Assets/Projects/muskaan.png";
import aviral from "../../Assets/Projects/aviral.png";

function Projects() {

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sentryvision}
              isBlog={false}
              title="Sentryvision"
              description="Empowering Public Safety through AI-Driven Innovation. Developed and implemented real-time video anomaly detection system using AI and ML, leading to a 90% reduction in response time to public safety incidents."
              ghLink="https://github.com/kushal7201/SentryVision"
            // demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sustainhub}
              isBlog={false}
              title="SustainHub"
              description="Empowering citizens to report public facility issues via a user-friendly web platform. Designed and developed web platform for public facility issue reporting and tracking, contributing to increase in issue resolution efficiency."
              ghLink="https://github.com/kushal7201/Sustain_Hub_GSC"
            // demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={movify}
              isBlog={false}
              title="Movify"
              description="Movie recommendation system based on blends within a group. Developed a movie recommendation system that the of blends within a group, enabling users to discover movies based on collective interests and preferences."
              ghLink="https://github.com/kushal7201/Hackout-project"
            // demoLink=""              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={medfix}
              isBlog={false}
              title="Medfix"
              description="Developed a streamlined resume-building application that simplifies the CV creation process by guiding users through a form-based interface to input personal details, educational background, skills, and interests, thereby eliminating the need for manual design and customization."
              ghLink="https://github.com/kushal7201/Medfix"
            // demoLink=""              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cv_maker}
              isBlog={false}
              title="CV Maker"
              description="Implemented a Doctor Appointment Booking System using PHP, enabling patients to easily schedule appointments with doctors online, enhancing patient-doctor communication and improving healthcare service efficiency."
              ghLink="https://github.com/kushal7201/CV-Maker"
            // demoLink=""              
            />
          </Col>

        </Row>
      </Container>
      <Container>
        <h1 className="project-heading">
          My App <strong className="purple">Designs </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few app designs projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={muskaan}
              isBlog={false}
              title="Muskaan"
              description="Designed an app facilitating connections between NGOs and potential donors, enabling efficient fulfillment of needs through targeted donations and redistribution of surplus goods to certified NGOs."
              // ghLink="https://github.com/kushal7201/SentryVision"
              demoLink="https://www.figma.com/design/eh2DY6YxMJvPFpMKYLdNZg/Muskaan-(Community)?node-id=0-1&t=4qCpBYxeXtgbnFFl-1"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={aviral}
              isBlog={false}
              title="Aviral"
              description="Designed an innovative app that displays college semester-wise results and analyzes them graphically, enhancing students' ability to visualize academic progress and performance trends."
              // ghLink="https://github.com/kushal7201/SentryVision"
              demoLink="https://www.figma.com/design/H1BQjR7dv7ru138xWJhBL4/Aviral?node-id=0-1&t=TwXedXDeUXmO1eCw-1"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
